var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: {
                placeholder: _vm.$t("operation.chatType"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.chatType,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "chatType", $$v)
                },
                expression: "listQuery.chatType"
              }
            },
            _vm._l(_vm.typeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _vm.listQuery.chatType === 2
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("user.inputPlayerId2") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.to,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "to", $$v)
                  },
                  expression: "listQuery.to"
                }
              })
            : _vm._e(),
          _vm.listQuery.chatType === 3
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("user.inputPlayerId3") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.to,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "to", $$v)
                  },
                  expression: "listQuery.to"
                }
              })
            : _vm._e(),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("operation.words") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.words,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "words", $$v)
              },
              expression: "listQuery.words"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter1 }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("statManager.searchReport")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm.listQuery.tableIde === 1
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("agents.id"),
                  prop: "id",
                  sortable: "custom",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                      }
                    }
                  ],
                  null,
                  false,
                  773642443
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.chatType"),
                  width: "150px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getChatType(scope.row.type)))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2733844175
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("user.playerId"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1905034046
                )
              }),
              _c("el-table-column", {
                attrs: { label: "to", width: "100px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.to))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3001259837
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.chatContent"),
                  width: "360px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3823322255
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.firstChargeTip"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.createTime"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getTime(scope.row.timestamp * 1000))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  688946721
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.tableIde === 2
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("agents.id"),
                  prop: "id",
                  sortable: "custom",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                      }
                    }
                  ],
                  null,
                  false,
                  773642443
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("user.playerId"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1905034046
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.chatType"),
                  width: "150px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getChatType1(scope.row.type)))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2529414174
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.reportId"),
                  width: "240px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.reportUid) +
                                " " +
                                _vm._s(_vm.getForbidInfo(scope.row.reportUid))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3313875142
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.chatContent"),
                  width: "360px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3823322255
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operation.reasontype"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getReportType(scope.row.reportTypes))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1986901826
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.createTime"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getTime(
                                  parseInt(scope.row.createTime) * 1000
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3904083609
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("default.operate"),
                  align: "center",
                  width: "160px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.state === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editItem(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("gameSetting.fb_handle")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          scope.row.state === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleReport(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("gameSetting.fb_nothandle")
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1404262875
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
          "page-sizes": [50, 100, 200]
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("gameSetting.inputRemark"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: { model: _vm.formData, "label-width": "100px" }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postInfo-container-item",
                  attrs: {
                    "label-width": "120px",
                    label: _vm.$t("operation.deadline")
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "datetime",
                      placeholder: _vm.$t("operation.selectDatetime"),
                      "value-format": "yyyy-MM-dd HH:mm",
                      format: "yyyy-MM-dd HH:mm",
                      align: "right",
                      "unlink-panels": ""
                    },
                    model: {
                      value: _vm.formData.datetime,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "datetime", $$v)
                      },
                      expression: "formData.datetime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "postInfo-container-item",
                  staticStyle: { "margin-left": "120px" },
                  attrs: { "label-width": "120px", type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.unblockButton(1)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("user.forbidChat")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "postInfo-container-item",
                  staticStyle: { "margin-left": "120px" },
                  attrs: { "label-width": "120px", type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.unblockButton(3)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("user.banDevice")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }